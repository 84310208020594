.border-radius {
  border-radius: 20px !important;
}

.tc-list {
  padding: 20px;
  list-style-type: circle;
}

.btn-headermenu-login {
  background-color: #fff;
  color: #01a781 !important;
  border-color: #fff !important;
  padding: 5px !important;
  margin-right: 10px;
}

.btn-headermenu-login:hover {
  background-color: #01a781;
  color: #fff !important
}

.btn-headermenu-login:focus {
  background-color: #067a5f;
  color: #fff !important
}

.btn-headermenu-register {
  border-color: #fff;
  padding: 5px !important;
  margin-right: 10px;
}

.btn-headermenu-register:hover {
  background-color: #067a5f;
  color: #fff !important
}

.btn-headermenu-register:focus {
  background-color: #065e49;
  color: #fff !important
}

.btn-disable {
  background-color: #ccc;
  border-color: #777;
}

.btn-disable:hover,
.btn-disable:focus {
  background-color: #ddd;
  border-color: #666;
}

.footer-divider {
  border: 1px solid;
  border-color: rgba(151, 175, 213, 0.1);
  height: 0.5px;
  margin: 20px 0
}

.card {
  max-height: 600px !important;
  cursor: pointer;

}

.card:hover {
  scale: 1.05;
}

.card img {
  max-height: 500px !important;
}

.card-title {
  margin-top: -15px !important;
}

.modal-image {
  border-radius: 15px !important;
}

.modal,
.modal div {
  background: none !important;
  background-color: none !important;
  border: none !important;
}

.modal-body,
.modal-header .model-content {
  padding: 0 !important;
}

.modal-content {
  background-color: none !important;
}

.modal-image {
  cursor: pointer;
}

.modal-header button {
  scale: 1.3;
  color: red !important;
}

.table-scroll {
  overflow-x: hidden;
}

.dropdown-menu {
  margin-top: -0.4rem !important;
  margin-left: -4.2rem !important;
  background-color: white;
  border: none !important;
  /* margin-top: -; */
}

.dropdown-item {
  color: #01a781;
}

.dropdown-item:hover {
  background-color: white !important;
}


@media (max-width: 992px) {
  .table-scroll {
    width: 100%;
    overflow-x: scroll;
  }
}

.td-width {
  min-width: 150px
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #07122c;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #01a781;
}